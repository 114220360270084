import httpClient from "@/services/httpClient";
import { server } from "@/services/constants";

// order
export const getOrders = (
  page,
  pageSize,
  search,
  durations = null,
  branch = null,
  status = null,
  transporter = null
) => {
  let dateDuration = "date";
  let pSearch = "search";
  let branchId = branch != "none" ? `/${branch}` : "/none";
  let statusCode = status != "none" ? `/${status}` : "/none";
  let transporterCode = status != "none" ? `/${transporter}` : "/none";

  if (search) {
    pSearch = search;
  }
  if (durations != "none") {
    dateDuration = durations;
  }
  return httpClient.get(
    server.ORDER_URL +
      `/all/page/${page}/${pageSize}/${pSearch}/${dateDuration}${branchId}${statusCode}${transporterCode}`
  );
};

export const getOrdersWaiting = (page, pageSize, search, branch_id = null, status = null, transporter = null) => {
  if (search == '') search = 'none';
  let branchId = branch_id != "none" ? `/${branch_id}` : "/none";
  let statusCode = status != "none" ? `/${status}` : "/none";
  let transporterCode = transporterCode != "none" ? `/${transporter}` : "/none";

  return httpClient.get(
    server.ORDER_URL + `/waiting/page/${page}/${pageSize}/${search}${branchId}${statusCode}${transporterCode}`
  );
};

export const addOrder = (data) => {
  return httpClient.post(server.ORDER_URL, data);
};

export const updateOrder = (data) => {
  return httpClient.put(server.ORDER_URL, data);
};

export const setOrderPaymentMethod = (data) => {
  return httpClient.put(server.ORDER_URL + `/setpaymentmethod`, data);
};

export const setOrderStatus = (data) => {
  return httpClient.put(server.ORDER_URL + `/setStatus`, data);
};

export const deleteOrder = (id) => {
  return httpClient.delete(server.ORDER_URL + `/id/${id}`);
};

export const updateBulkOrderStatusToSuccess = (data) => {
  return httpClient.put(
    server.ORDER_URL + `/setBulkOrderStatusToSuccess`,
    data
  );
};

export const updateBulkOrderStatusToBooking = (data) => {
  return httpClient.put(
    server.ORDER_URL + `/updateBulkOrderStatusToBooking`,
    data
  );
};

export const updateBulkOrderStatusToKAPrinted = (data) => {
  return httpClient.put(
    server.ORDER_URL + `/updateBulkOrderStatusToKAPrinted`,
    data
  );
};

export const updateBulkOrderStatusToKERRYPrinted = (data) => {
  return httpClient.put(
    server.ORDER_URL + `/updateBulkOrderStatusToKERRYPrinted`,
    data
  );
};

export const updateBulkOrderStatus = (data) => {
  return httpClient.put(server.ORDER_URL + `/setBulkOrderStatus`, data);
};

export const updateBulkOrderStatusToPrinted = (data) => {
  return httpClient.put(
    server.ORDER_URL + `/setBulkOrderStatusToPrinted`,
    data
  );
};

export const getOrder = (id) => {
  return httpClient.get(server.ORDER_URL + `/id/${id}`);
};

export const getOrdersById = (data) => {
  return httpClient.post(server.ORDER_URL + `/getOrdersById`, data);
};

export const getProductByIds = (data) => {
  return httpClient.post(server.ORDER_URL + `/getProductByIds`, data);
};

export const getOrderHistory = (id, page, pageSize) => {
  return httpClient.get(
    server.ORDER_URL + `/history/id/${id}/page/${page}/${pageSize}`
  );
};

export const getOrderTotal = () => {
  return httpClient.get(server.ORDER_URL + `/total`);
};
export const getOrderTotalCOD = () => {
  return httpClient.get(server.ORDER_URL + `/total/cod`);
};

export const getOrderTotalTranfer = () => {
  return httpClient.get(server.ORDER_URL + `/total/tranfer`);
};

export const getOrderTotalWait = () => {
  return httpClient.get(server.ORDER_URL + `/total/wait`);
};
export const getOrderTotalCODWait = () => {
  return httpClient.get(server.ORDER_URL + `/total/cod/wait`);
};

export const getOrderTotalTranferWait = () => {
  return httpClient.get(server.ORDER_URL + `/total/tranfer/wait`);
};

export const getOrderTotalRelateById = (id) => {
  return httpClient.get(server.ORDER_URL + `/total/id/${id}`);
};
